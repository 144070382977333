import type { EmailAddress } from '@/domain/emails';
import type { Enum } from '@/domain/enum';
import type { Phone } from '@/domain/phone';
import { EnvironmentService } from '@/services/environment';
import { SentryService } from '@/services/sentry';
import type { Location } from '@remix-run/router';
import pick from 'lodash/pick';
import type { Moment } from 'moment';
import moment from 'moment';
import pLimit from 'p-limit';
import queryString from 'query-string';
import message from './toast';

// TODO: Implement success callback page that gets the token and store it in the localStorage
const getCallbackUrl = () => `${window.location.origin}/`;

export const buildLoginUrl = async () => {
  const href = await EnvironmentService.getAuthClientUrl();
  return `${href}/login?callbackUrl=${getCallbackUrl()}&returnTo=/`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrorMessage = (error: any, defaultMessage?: string) => {
  const msg = error.displayMessage || error.message;

  if (defaultMessage) {
    return msg || defaultMessage;
  }

  return msg;
};

type HandleErrorOptions = {
  displayToast?: boolean;
  toastMessage?: string;
  toastFallbackMessage?: string;
  rethrowError?: boolean;
  sendToSentry?: boolean;
};
export const handleError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  {
    displayToast = true,
    toastMessage,
    toastFallbackMessage = 'Unexpected Error',
    rethrowError,
    sendToSentry = true,
  }: HandleErrorOptions,
) => {
  if (displayToast) {
    const errorMessage = toastMessage ?? getErrorMessage(error, toastFallbackMessage);
    message.error(errorMessage);
  }
  if (sendToSentry) {
    SentryService.trackError(error);
  }

  if (rethrowError) {
    throw error;
  }
};

export const formatPartyRole = (partyRole?: string): string => {
  switch (partyRole) {
    case 'USER':
    case 'PROPERTY_MANAGER':
      return 'Property Manager';
    case 'RESIDENT':
      return 'Resident';
    case 'VENDOR':
      return 'Vendor';
    default:
      return partyRole || '';
  }
};

export const isSameDay = (dateTime1: string, dateTime2: string): boolean => {
  return moment.utc(dateTime1).isSame(moment.utc(dateTime2), 'day');
};

export const getCardTime = (time: string | Moment) => moment(time).format('h:mm A');

export const capitalize = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatCurrency = (
  value?: string | number,
  options: { accountingStyle?: boolean; noEmptyDecimals?: boolean } = {
    accountingStyle: false,
    noEmptyDecimals: false,
  },
) => {
  if (value == null) {
    return '';
  }

  const numericValue = Number(value);

  if (options.accountingStyle && numericValue < 0) {
    const formatted = currencyFormatter.format(-numericValue);
    return `(${options.noEmptyDecimals ? formatted.replace(/\D00$/, '') : formatted})`;
  }

  const formatted = currencyFormatter.format(numericValue);
  return options.noEmptyDecimals ? formatted.replace(/\D00$/, '') : formatted;
};

export const formatAge = (placedInServiceDate: string) => {
  const duration = moment.duration(moment().diff(placedInServiceDate));

  return [
    duration.years() > 0 ? `${duration.years()} y` : null,
    duration.months() > 0 ? `${duration.months()} m` : null,
    duration.days() > 0 ? `${duration.days()} d` : null,
  ]
    .filter((x) => x)
    .join(', ');
};

const getPrimary = <R extends { primary?: boolean }>(emailList: R[]): R | undefined =>
  emailList.find((e) => e.primary) || emailList[0];

export const getPrimaryEmail = (emailList: EmailAddress[]): EmailAddress | undefined =>
  getPrimary(emailList);

export const getPrimaryPhone = (phones: Phone[]): Phone | undefined => getPrimary(phones);

export const getLocationParams = (location: Location) => queryString.parse(location.search);

export const getFirst = <T = string>(
  objectOrArray: T | T[] | undefined | null,
): T | undefined | null => (objectOrArray instanceof Array ? objectOrArray[0] : objectOrArray);

/**
 * Use this function instead of 'lodash/chain'
 * @see https://skyboxcapital.atlassian.net/browse/PN-4485
 * @see https://github.com/lodash/lodash/issues/4712
 */
export const groupArrayBy = <T, KeyType = string>(
  array: T[],
  key: keyof T,
  sortFn?: (a: T, b: T) => number,
) => {
  const result = new Map<unknown, T[]>();

  array.forEach((item) => {
    const value = item[key];
    if (!result.has(value)) {
      result.set(value, [item]);
    } else {
      result.get(value)!.push(item);
    }
  });

  return [...result].map(([k, value]) => ({
    key: k as KeyType,
    value: sortFn ? value.sort(sortFn) : value,
  }));
};

export const prettierLabel = (label: string) => {
  return label
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
};

export const pluralize = (quantity: number, singularLabel: string, pluralLabel: string) =>
  quantity === 1 ? singularLabel : pluralLabel;

export const sortArrayByDateField = <T>(array: T[], field: keyof T) =>
  // @ts-ignore
  array.slice(0).sort((i1, i2) => moment(i1[field]).valueOf() - moment(i2[field]).valueOf());

/** @see https://skyboxcapital.atlassian.net/browse/FB-266 */
export const getFileUploadLimit = (concurrentUploads = 2) => pLimit(concurrentUploads);

const parseToOption = <V = string, T = Record<string, any>>(
  obj: T,
  labelPropName: keyof T = 'displayName' as keyof T,
  valuePropName: keyof T = 'value' as keyof T,
  metaKeys: (keyof T)[] = [] as (keyof T)[],
) => ({
  label: obj[labelPropName] as unknown as string,
  value: obj[valuePropName] as unknown as V,
  ...(metaKeys.length ? { meta: pick(obj, metaKeys) } : {}),
});

export const enumToOptions = <T = any, R extends Enum<T> = Enum<T>>(enumItems: R[]) =>
  (enumItems || []).map((enumItem) => parseToOption<T>(enumItem));

export const arrayToMap = <T extends object, K extends keyof any = keyof any, V = T>(
  arr: T[],
  getKey: (item: T) => K,
  getValue?: (item: T) => V,
): Partial<Record<K, V>> =>
  arr.reduce((map, item) => ({ ...map, [getKey(item)]: getValue ? getValue(item) : item }), {});
